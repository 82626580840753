<template>
    <div>
        <van-popup v-model="show" position="bottom" :style="{ height: '60%' }" @click-overlay="close()">
            <div class="rap">
                <div class="title">
                    优惠券 <span>{{list.length}}张</span>
                </div>
                <div class="box scollows">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                    >
                    <div class="item" v-for="(item,index) in list" :key="index" @click="selectItem(item)">
                        <div class="top">
                            <div class="right">
                                <div class="_left">
                                    <div class="__top">
                                        <span>30</span>
                                        <span style="font-size:12px;">天</span>
                                    </div>
                                    <div class="__bottom">套餐有效期</div>
                                </div>
                                
                            </div>
                            <div class="left">
                                <div class="_up">{{item.coupon_name}}</div>
                                <div class="_down">{{item.start_time}} 至 {{item.expire_time}}</div>
                            </div>
                        </div>
                        <div class="down">
                           <div>适用范围：体验套餐不可用</div>
                           <div class="_right" v-if="moneyitem.price >= item.amount_limit">
                                <img src="~@/assets/images/selectpack/blueActive.png" v-if="selist[0]?selist[0].id == item.id:false" alt="">
                                <img src="~@/assets/images/selectpack/noActive.png" v-if="selist[0]?selist[0].id != item.id:true" alt="">
                            </div>
                           <div class="_right" v-else>
                                <van-radio disabled></van-radio>
                            </div>
                        </div>
                    </div>
                    </van-list>
                </div>
                <div class="bottom-btn">
                    <div></div>
                    <!-- <div class="left">优惠金额￥ <span>{{selist[0]? Math.trunc(selist[0].value):0}}</span></div> -->
                    <div class="right" @click="close()">确定</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>

// import coupons from "@/mixin/coupons.js";
export default {
    props: {
        showf: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        selist: {
            type: Array,
            default: () => {
                return []
            }
        },
        page:{
            type: Number,
            default: () => {
                return 1
            }
        },
        moneyitem:{
            type: Object,
            default:() => {
                return {}
            }
        }
    },
    data() {
        return {
            show: false,
            loading: false,
            finished: false,
            // list:[],
        }
    },
    // mixins: [coupons],
    mounted() {
        // this.$nextTick(()=>{
        //     console.log(this.moneyitem,'mn');
        // })
    },
    updated(){
        if(this.showf){
            this.$nextTick(()=>{
                const selectWrap = document.querySelector ('.scollows')
                selectWrap.addEventListener ('scroll', this.scrollLoadMore);
            })
        }
    },
    methods: {
        // 加载更多
        scrollLoadMore() {
            let scrollWrap = document.querySelector ('.scollows');
            var currentScrollTop = scrollWrap.scrollTop;
            var currentOffsetHeight = scrollWrap.scrollHeight;
            var currentClientHeight = scrollWrap.clientHeight;
            this.loading = true
            if ((currentScrollTop + currentClientHeight == currentOffsetHeight)){
                this.$emit('nextPage',this.page)
                this.finished = true
            }
        },
        selectItem(item) {
            this.$emit('selectCoupsiItem',item)
        },
        close() {
            this.show = false
            this.$emit('closepop')
        }

    },
    watch: {
        showf: {
            handler() {
                this.show = this.showf
            },
            immediate: true
        }
    }

}
</script>
<style lang="less" scoped>
.rap {
    width: 100%;
    height: 100%;
    background-color: #f2f3f5;
    // padding: 20px 15px;
    box-sizing: border-box;
    position: relative;

    .title {
        font-size: 18px;
        padding: 20px 15px;
        box-sizing: border-box;
        font-weight: 600;

        span {
            font-size: 12px;
            color: #999999;
        }

        // margin-bottom: 20px;
    }

    .box {
        width: 100%;
        height: 320px;
        padding: 10px 15px;
        padding-bottom: 100px;
        background-color: #f2f3f5;
        box-sizing: border-box;
        overflow-y: scroll;

        .item {
            height: 104px;
            width: 100%;
            background-color: #2d78f6;
            background: url('~@/assets/images/selectpack/cardBg.png') no-repeat;
            background-size: 100%;
            margin-bottom: 10px;
            padding: 12px 14px;
            box-sizing: border-box;


            .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 50px;

                .left {
                    ._up {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }

                    ._down {
                        display: inline-block;
                        color: #8290A8;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    ._left {
                        .__top {
                            font-size: 36px;
                            color: #EA3737;
                            font-family: 'bahn';
                            font-weight: bold;
                            text-align: center;
                        }

                        .__bottom {
                            font-size: 12px;
                            color: #EA3737;
                            background: rgba(234, 110, 55, .3);
                            padding: 2px 3px;
                        }
                    }

                    
                }
            }

            .down {
                color: #b6bcc7;
                height: 30px;
                line-height: 38px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                ._right {
                    margin-left: 10px;

                    img {
                        width: 18px;
                        height: 18px;

                    }
                }
            }
        }
        // .item:last-child{
        //     // margin-bottom: 104px;
        // }

    }

    .bottom-btn {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        // padding-top: 10px;
        align-items: center;
        padding: 0 15px;
        box-sizing: border-box;
        height: 70px;
        width: 100%;
        background-color: #f9fafc;
        z-index: 99;

        .left {
            font-size: 14px;
            font-weight: 600;

            // color: aqua;
            span {
                font-size: 18px;
            }
        }

        .right {
            width: 100px;
            height: 36px;
            background-color: #2d78f6;
            color: #fff;
            text-align: center;
            line-height: 36px;
            border-radius: 8px;
        }
    }
}
</style>